import request from '@/utils/request'

export const addAdvertisement: any = (data: any) => {
    return request.post('/ymAdvertise', data)
}
export const updataAdvertisement: any = (data: any) => {
    return request.put('/ymAdvertise', data)
}
export const advertisementList: any = (data: any) => {
    return request.post('/ymAdvertise/pageList', data)
}
export const setEnable: any = (id: any) => {
    return request.put(`/ymAdvertise/setEnable/${id}`)
}
export const getIdSearch: any = (id: any) => {
    return request.get(`/ymAdvertise/${id}`)
}
export const deleteAdvertisement: any = (id: any) => {
    return request.delete(`/ymAdvertise/${id}`)
}